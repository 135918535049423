import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { addEmailToDatabase } from '../../firebase/firebase';

import { visuallyHidden } from '@mui/utils';
import { styled } from '@mui/material/styles';

const StyledBox = styled('div')(({ theme }) => ({
  alignSelf: 'center',
  width: '100%',
  height: 400,
  marginTop: theme.spacing(8),
  borderRadius: theme.shape.borderRadius,
  boxShadow:
    theme.palette.mode === 'light'
      ? '0 0 12px 8px hsla(220, 25%, 80%, 0.2)'
      : '0 0 24px 12px hsla(210, 100%, 25%, 0.2)',
  outline: '1px solid',
  backgroundImage: `url(${theme.palette.mode === 'light'
      ? '/static/images/templates/templates-images/hero-light.png'
      : '/static/images/templates/templates-images/hero-dark.png'
    })`,
  backgroundSize: 'cover',
  outlineColor:
    theme.palette.mode === 'light'
      ? 'hsla(220, 25%, 80%, 0.5)'
      : 'hsla(210, 100%, 80%, 0.1)',
  [theme.breakpoints.up('sm')]: {
    marginTop: theme.spacing(10),
    height: 700,
  },
}));

export default function Hero() {
  const [email, setEmail] = useState('');

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();  // Prevent default form submission behavior
    addEmailToDatabase(email);
    setEmail('');  // Clear the input after submitting
  };

  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
        /*backgroundImage:
          theme.palette.mode === 'light'
            ? 'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(265, 90%, 70%), transparent)'
            : 'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(265, 90%, 50%), transparent)',
        backgroundRepeat: 'no-repeat', */
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Stack
          spacing={2}
          alignItems="center"
          useFlexGap
          sx={{ width: { xs: '100%', sm: '70%' } }}
        >
          <Typography
            variant="h1"
            sx={{
              display: 'flex',
              fontWeight: 'bold',
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: 'center',
              fontSize: 'clamp(3rem, 10vw, 3.5rem)',
              color: (theme) =>
                theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
            }}
          >
            shop
            <Typography
              component="span"
              variant="h1"
              sx={{
                fontSize: 'inherit',
                fontWeight: 'bold',
                color: (theme) =>
                  theme.palette.mode === 'light' ? 'black' : 'white',
              }}
            >
              smarter
            </Typography>
          </Typography>
          <Typography
            textAlign="center"
            color="text.secondary"
            sx={{ width: { sm: '100%', md: '80%' } }}
          >
            Search for products with AI driven recommendations. Give ShoppaBot a detailed description of what you're looking for, and find the perfect match the first time. You can even upload pictures for ShoppaBot to analyze!
          </Typography>
          <Typography
            textAlign="center"
            color="text.primary"
            fontWeight="bold"
            sx={{ width: { sm: '100%', md: '80%' } }}
          >
            Want to be the first to know when we launch? Sign up for our newsletter!
          </Typography>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={1}
            useFlexGap
            sx={{ pt: 2, width: { xs: '100%', sm: 'auto' } }}
          >
          <form onSubmit={handleSubmit}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1} sx={{ pt: 2 }}>
            <InputLabel htmlFor="email-hero" sx={visuallyHidden}>
              Email
            </InputLabel>
            <TextField
              id="email-hero"
              value={email}
              onChange={handleEmailChange}
              hiddenLabel
              size="small"
              variant="outlined"
              placeholder="Your email address"
              inputProps={{ autoComplete: 'off' }}
            />
            <Button type="submit" variant="contained" color="primary">
              Get Notified
            </Button>
          </Stack>
        </form>
          </Stack>
          <Typography variant="caption" textAlign="center">
            By clicking &quot;Get Notified&quot; you agree to our&nbsp;
            <Link href="#" color="primary">
              Terms & Conditions
            </Link>
            .
          </Typography>
        </Stack>
      </Container>
    </Box>
  );
}
