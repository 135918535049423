import * as React from 'react';

export default function SitemarkIcon() {
  return (
    <img
      src="./SHOPPA_Bot_Logo.png" // Adjust the path to where your image is stored
      alt="Logo" // Provide a meaningful alternate text for accessibility
      style={{ height: '100px', width: '100px', marginRight: '8px' }} // Adjust the size and spacing as needed
    />
  );
}
