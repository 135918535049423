// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection, addDoc } from 'firebase/firestore';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAHH568U9EdxQLJ_OfZlteZ4QqkzvOk-wg",
  authDomain: "shoppa-bot-59785.firebaseapp.com",
  projectId: "shoppa-bot-59785",
  storageBucket: "shoppa-bot-59785.appspot.com",
  messagingSenderId: "507744412296",
  appId: "1:507744412296:web:53d80532fd3064c8c5f53d",
  measurementId: "G-RJBHYJRZNV"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const db = getFirestore(app);

async function addEmailToDatabase(email) {
  try {
    const docRef = await addDoc(collection(db, "mailing-list-signup"), {
      email: email,
      timestamp: new Date()
    });
    console.log("Document written with ID: ", docRef.id);
  } catch (e) {
    console.error("Error adding document: ", e);
  }
}

export { addEmailToDatabase };